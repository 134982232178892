import { inject, Injectable } from '@angular/core';
import { CookieService, PlatformService } from 'ngx-unificator/services';
import { distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { GlobalEventsService } from './global-events.service';
import { LanguageService } from './language/language.service';
import { UserService } from './user/user.service';
import { CmsContentMapperService } from './cms-content-mapper.service';
import { CmsApiService } from './api/cms-api.service';
import { UserGroupsService } from './user/user-groups.service';
import { USER_HAVE_ONE_DEPOSIT_GROUP } from './user/data/user-group.data';
import { HostRequestsInterceptor } from '../interceptors/host-requests.interceptor';

declare global {
  interface Window {
    OneSignal: any;
    OneSignalDeferred: any;
  }
}

declare var OneSignal;

export const EXCLUDE_PUSH_PROMPT_PAGES = [
  '/404',
  '/403',
  'login',
  'registration',
  'deposit',
];

@Injectable({
  providedIn: 'root',
})
export class OneSignalService {
  private _platform = inject(PlatformService);
  private _globalEvents = inject(GlobalEventsService);
  private _cookie = inject(CookieService);
  private _groups = inject(UserGroupsService);
  private _language = inject(LanguageService);
  private _user = inject(UserService);
  private _api = inject(CmsApiService);
  private _mapper = inject(CmsContentMapperService);
  private _hostInterceptor = inject(HostRequestsInterceptor);

  private _appId = '2d52d07a-8a63-4d31-850f-eeb9016d3e20';

  /**
   * Get one signal id from CMS
   */
  handleId() {
    if (this._platform.isBrowser) {
      this._api.staticContentItem({ slug: 'one-signal-ids' }).pipe(
        first(),
        map(response => this._mapper.mapCmsData(
          response.data && response.data.item, { name: 'name' })[0]),
        map(item => item?.Grouplist),
        map(mirrors => {
          const hostname = this._hostInterceptor.getDomainName().split('.')[1];
          return mirrors[hostname]?.id?.trim() || this._appId;
        }),
        tap((appId: string) => this._onInit(appId))
      ).subscribe();
    }
  }

  /**
   * Init one signal and apply id from CMS
   */
  private _onInit(id: string): void {
    window.OneSignalDeferred = window.OneSignalDeferred || [];
    window.OneSignalDeferred.push(async () => {
      await OneSignal.init({
        appId: id,
        notifyButton: {
          enable: true,
        },
        serviceWorkerParam: { scope: '/push/onesignal/' },
        serviceWorkerPath: 'push/onesignal/OneSignalSDKWorker.js',
      }).then(() => {
        this._autoUpdateUser();
        this._setExternalId();
      });
    });
  }

  // This function will work only if Onesignal init. So it will don`t work for localhost or stage
  // And will not send test users data. To make it work change in Onesignal admin panel in app test url to localhost
  private _autoUpdateUser() {
    this._globalEvents.routerNavigationEnd$.pipe(
      distinctUntilChanged(),
      tap((data) => {
        OneSignal.push(() => {
          this._setUserTags(this._createUserTagsObj());
        });
      }),
    ).subscribe();
  }

  private _setUserTags(data) {
    OneSignal?.User?.addTags(data);
  }

  private _createUserTagsObj() {
    if (this._user.auth) {
      return {
        isUserRegistered: this._cookie.check('registered')?.toString(),
        isUserHadDeposit: (this._groups.isExistGroup(USER_HAVE_ONE_DEPOSIT_GROUP) || this._user.hasDeposit || this._user.accountList.some((e => e.amount > 0)))?.toString(),
        userLanguage: this._language.current,
        isHaveBalance: this._user.accountList.some((e => e.amount > 0))?.toString(),
      };
    } else {
      return {
        isUserRegistered: this._cookie.check('registered')?.toString(),
        userLanguage: this._language.current,
      };
    }
  }


  private _setExternalId() {
    OneSignal.push(async () => {
      if (OneSignal?.User?.PushSubscription?.id) {
        OneSignal.login(OneSignal.User.PushSubscription.id);
      }
    });
  }
}
