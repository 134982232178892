import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { forkJoin, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user/user.service';
import { first, tap } from 'rxjs/operators';
import { TranslationService } from '../shared/translation/translation.service';
import { LanguageService } from './language/language.service';
import { switchMap } from 'rxjs/operators';
import { CommonDataService } from './common-data.service';
import { OneSignalService } from './one-signal.service';
import { ArrayService } from 'ngx-unificator/services';

@Injectable({
  providedIn: 'root'
})
export class DataPreloaderService  {
  private _env = inject(EnvironmentService);
  private _user = inject(UserService);
  private _translate = inject(TranslationService);
  private _language = inject(LanguageService);
  private _array = inject(ArrayService);
  private _commonData = inject(CommonDataService);
  private _oneSignal = inject(OneSignalService);


  /**
   * List of observables that need to be preloaded before app start
   */
  private _dataToPreload: Array<any> = [
    this._translate.getUserTranslates$().pipe(first()),
    this._user.auth$.pipe(first()),
  ];

  /**
   * Is data loaded
   */
  private _loaded: boolean;

  /**
   * Access to _loaded
   */
  get loaded(): boolean {
    return this._loaded;
  }

  /**
   * For main route resolving
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._env.env$.pipe(
      first(),
      tap(() => {
        if (!this._language.current) {
          const params = this._array.routeToArray(state.url);
          const routeLang = params[0];

          this._language.changeLang(this._language.detectUserLang(routeLang, this._env.env.locale.short, this._env.env.languageList));
        }
      }),
      tap(() => {
        if (this._env.env.country.short === 'fr') {
          this._dataToPreload.push(this._commonData.getFrStags());
        } else {
          this._env.isSignupRestricted$.next(false);
        }
      }),
      switchMap(() => forkJoin(this._dataToPreload)),
      tap(() => {
        this._loaded = true;
        this._oneSignal.handleId();
      })
    );
  }

}
